/* src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: "Anton";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3Kz-C8.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193,
		U+2212, U+2215, U+FEFF, U+FFFD;
}
